.tile__checkbox {
    position: relative;
    width: 100%;
    max-width: 87.5px;
    height: 100%;
    max-height: 87.5px;
    float: left;
    box-sizing: border-box;
    text-align: center;
    aspect-ratio: 1;
}

/*  Fallback for unsupported property aspect-ratio on older browsers */
@supports not (aspect-ratio: 1) {
    .tile__checkbox::before {
        float: left;
        padding-top: 100%;
        content: '';
    }

    .tile__checkbox::after {
        display: block;
        content: '';
        clear: both;
    }
}

.tile__checkbox div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 25px;
    transition: 0.5s ease;
    border-radius: 4px;
}

.tile__checkbox input {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.tooltip__area {
    position: absolute;
}

.top-section__text {
    font-weight: bold;
    font-size: 22px;
}
