@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Raleway:wght@400;500;600;700;800&display=swap');

html,
body,
#root,
#app > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Telegraf', sans-serif;
}

html {
    height: 100%;
}
body {
    min-height: 100%;
}

button {
    outline: none !important;
}

a {
    color: inherit;
    text-decoration: inherit;
}

.app-loader {
    z-index: 9999;
    position: fixed;
    top: 0;
    width: 100%;
    height: 2px;
}
/*
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
    background: #673FFA;
    height: 18px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
} */

@font-face {
    font-family: 'Telegraf';
    font-weight: 200;
    src: local('Telegraf'), url(./resources/fonts/Telegraf-UltraLight.otf) format('opentype');
}
@font-face {
    font-family: 'Telegraf';
    font-weight: 400;
    src: local('Telegraf'), url(./resources/fonts/Telegraf-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'Telegraf';
    font-weight: 500;
    src: local('Telegraf'), url(./resources/fonts/Telegraf-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'Telegraf';
    font-weight: 600;
    src: local('Telegraf'), url(./resources/fonts/Telegraf-SemiBold.otf) format('opentype');
}

@font-face {
    font-family: 'Telegraf';
    font-weight: 700;
    src: local('Telegraf'), url(./resources/fonts/Telegraf-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Telegraf';
    font-weight: 800;
    src: local('Telegraf'), url(./resources/fonts/Telegraf-UltraBold.otf) format('opentype');
}

@font-face {
    font-family: 'Monument Extended';
    font-weight: 400;
    src: local('Telegraf'), url(resources/fonts/MonumentExtended-Regular.otf) format('opentype');
}
@font-face {
    font-family: 'Monument Extended';
    font-weight: 900;
    src: local('Telegraf'), url(resources/fonts/MonumentExtended-Ultrabold.otf) format('opentype');
}

@font-face {
    font-family: 'Apotek Cond';
    font-weight: 900;
    src: local('Apotec Cond'), url(resources/fonts/Apotek_Cond_Black.otf) format('opentype');
}

@font-face {
    font-family: 'Apotek Cond';
    font-weight: 700;
    src: local('Apotec Cond'), url(resources/fonts/Apotek_Cond_Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Apotek Cond';
    font-weight: 600;
    src: local('Apotec Cond'), url(resources/fonts/Apotek_Cond_Semibold.otf) format('opentype');
}

@font-face {
    font-family: 'Apotek Cond';
    font-weight: 500;
    src: local('Apotec Cond'), url(resources/fonts/Apotek_Cond_Medium.otf) format('opentype');
}

@font-face {
    font-family: 'Apotek Cond';
    font-weight: 400;
    src: local('Apotec Cond'), url(resources/fonts/Apotek_Cond_Regular.otf) format('opentype');
}

@font-face {
    font-family: 'Apotek Cond';
    font-weight: 300;
    src: local('Apotec Cond'), url(resources/fonts/Apotek_Cond_Light.otf) format('opentype');
}

@font-face {
    font-family: 'Apotek Cond';
    font-weight: 200;
    src: local('Apotec Cond'), url(resources/fonts/Apotek_Cond_ExtraLight.otf) format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 100;
    src: local('Roboto'), url(resources/fonts/Roboto-Thin.ttf) format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 300;
    src: local('Roboto'), url(resources/fonts/Roboto-Light.ttf) format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    src: local('Roboto'), url(resources/fonts/Roboto-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    src: local('Roboto'), url(resources/fonts/Roboto-Medium.ttf) format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 700;
    src: local('Roboto'), url(resources/fonts/Roboto-Bold.ttf) format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 900;
    src: local('Roboto'), url(resources/fonts/Roboto-Black.ttf) format('opentype');
}
