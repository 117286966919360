@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Raleway:wght@400;500;600;700;800&display=swap);
.container {
    height: 24px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 78px;
    height: 24px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bbbbbb;
    transition: 0.4s;
    border-radius: 3px;
}

.slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 38px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    transition: 0.4s;
    border-radius: 3px;
}

input:checked + .slider {
    background-color: #673ffa;
}

.switchDisabled {
    opacity: 0.7;
    cursor: default;
    background-color: #bbbbbb !important;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    transform: translateX(38px);
}

.onSwitch {
    display: none;
}

.onSwitch,
.offSwitch {
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 25%;
    left: 25%;
    font-size: 14px;
    font-family: Verdana, sans-serif;
}

.onSwitch {
    top: 12px;
}

.offSwitch {
    left: auto;
    right: 0;
    top: 12px;
}

input:checked + .slider .onSwitch {
    display: block;
}

input:checked + .slider .offSwitch {
    display: none;
}

.tile__checkbox {
    position: relative;
    width: 100%;
    max-width: 87.5px;
    height: 100%;
    max-height: 87.5px;
    float: left;
    box-sizing: border-box;
    text-align: center;
    aspect-ratio: 1;
}

/*  Fallback for unsupported property aspect-ratio on older browsers */
@supports not (aspect-ratio: 1) {
    .tile__checkbox::before {
        float: left;
        padding-top: 100%;
        content: '';
    }

    .tile__checkbox::after {
        display: block;
        content: '';
        clear: both;
    }
}

.tile__checkbox div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 25px;
    transition: 0.5s ease;
    border-radius: 4px;
}

.tile__checkbox input {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.tooltip__area {
    position: absolute;
}

.top-section__text {
    font-weight: bold;
    font-size: 22px;
}

html,
body,
#root,
#app > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Telegraf', sans-serif;
}

html {
    height: 100%;
}
body {
    min-height: 100%;
}

button {
    outline: none !important;
}

a {
    color: inherit;
    text-decoration: inherit;
}

.app-loader {
    z-index: 9999;
    position: fixed;
    top: 0;
    width: 100%;
    height: 2px;
}
/*
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
    background: #673FFA;
    height: 18px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
} */

@font-face {
    font-family: 'Telegraf';
    font-weight: 200;
    src: local('Telegraf'), url(/static/media/Telegraf-UltraLight.07280a76.otf) format('opentype');
}
@font-face {
    font-family: 'Telegraf';
    font-weight: 400;
    src: local('Telegraf'), url(/static/media/Telegraf-Regular.c32eaa95.otf) format('opentype');
}

@font-face {
    font-family: 'Telegraf';
    font-weight: 500;
    src: local('Telegraf'), url(/static/media/Telegraf-Medium.abea385d.otf) format('opentype');
}

@font-face {
    font-family: 'Telegraf';
    font-weight: 600;
    src: local('Telegraf'), url(/static/media/Telegraf-SemiBold.b83bc1b0.otf) format('opentype');
}

@font-face {
    font-family: 'Telegraf';
    font-weight: 700;
    src: local('Telegraf'), url(/static/media/Telegraf-Bold.aad82fe7.otf) format('opentype');
}

@font-face {
    font-family: 'Telegraf';
    font-weight: 800;
    src: local('Telegraf'), url(/static/media/Telegraf-UltraBold.a3d5ccdf.otf) format('opentype');
}

@font-face {
    font-family: 'Monument Extended';
    font-weight: 400;
    src: local('Telegraf'), url(/static/media/MonumentExtended-Regular.db74f7a5.otf) format('opentype');
}
@font-face {
    font-family: 'Monument Extended';
    font-weight: 900;
    src: local('Telegraf'), url(/static/media/MonumentExtended-Ultrabold.9db16afe.otf) format('opentype');
}

@font-face {
    font-family: 'Apotek Cond';
    font-weight: 900;
    src: local('Apotec Cond'), url(/static/media/Apotek_Cond_Black.51afa6eb.otf) format('opentype');
}

@font-face {
    font-family: 'Apotek Cond';
    font-weight: 700;
    src: local('Apotec Cond'), url(/static/media/Apotek_Cond_Bold.a5421de2.otf) format('opentype');
}

@font-face {
    font-family: 'Apotek Cond';
    font-weight: 600;
    src: local('Apotec Cond'), url(/static/media/Apotek_Cond_Semibold.c847fc40.otf) format('opentype');
}

@font-face {
    font-family: 'Apotek Cond';
    font-weight: 500;
    src: local('Apotec Cond'), url(/static/media/Apotek_Cond_Medium.15bc0240.otf) format('opentype');
}

@font-face {
    font-family: 'Apotek Cond';
    font-weight: 400;
    src: local('Apotec Cond'), url(/static/media/Apotek_Cond_Regular.6e65bb7d.otf) format('opentype');
}

@font-face {
    font-family: 'Apotek Cond';
    font-weight: 300;
    src: local('Apotec Cond'), url(/static/media/Apotek_Cond_Light.63515c1c.otf) format('opentype');
}

@font-face {
    font-family: 'Apotek Cond';
    font-weight: 200;
    src: local('Apotec Cond'), url(/static/media/Apotek_Cond_ExtraLight.9502ce84.otf) format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 100;
    src: local('Roboto'), url(/static/media/Roboto-Thin.2745c061.ttf) format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 300;
    src: local('Roboto'), url(/static/media/Roboto-Light.ce61b8b6.ttf) format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    src: local('Roboto'), url(/static/media/Roboto-Regular.6bede58e.ttf) format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    src: local('Roboto'), url(/static/media/Roboto-Medium.0fcd45fb.ttf) format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 700;
    src: local('Roboto'), url(/static/media/Roboto-Bold.17451a4c.ttf) format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 900;
    src: local('Roboto'), url(/static/media/Roboto-Black.988a1a31.ttf) format('opentype');
}

